<script>
// import FullCalendar from "@fullcalendar/vue";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import bootstrapPlugin from "@fullcalendar/bootstrap";
// import listPlugin from "@fullcalendar/list";

import { DatePicker } from "v-calendar";

import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

// import { calendarEvents, categories } from "./data-calendar";
import useNetw from "@useNetw";
import ModalServiceComponent from "@/components/modalServiceComponent.vue";

/**
 * Calendar component
 */
export default {
  page: {
    title: "การเข้ารับบริการ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker, ModalServiceComponent },
  data() {
    return {
      title: "การเข้ารับบริการ",
      items: [
        {
          text: "บริการ",
          active: true,
        },
        {
          text: "การเข้ารับบริการ",
          active: false,
        },
      ],
      // calendarEvents: calendarEvents,
      // calendarOptions: {
      //   headerToolbar: {
      //     left: "prev,next today",
      //     center: "title",
      //     right: "dayGridMonth",
      //   },
      //   plugins: [
      //     dayGridPlugin,
      //     timeGridPlugin,
      //     interactionPlugin,
      //     bootstrapPlugin,
      //     listPlugin,
      //   ],
      //   initialView: "dayGridMonth",
      //   themeSystem: "bootstrap",
      //   initialEvents: calendarEvents,
      //   editable: true,
      //   droppable: true,
      //   eventResizableFromStart: true,
      //   dateClick: this.dateClicked,
      //   eventClick: this.editEvent,
      //   eventsSet: this.handleEvents,
      //   weekends: true,
      //   selectable: true,
      //   selectMirror: true,
      //   dayMaxEvents: true,
      // },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      // categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
      userBranch: "",
      rows: [],
      currentPage: 1,
      perPage: 10,
      overlayFlag: false,
      date: new Date(),
      attrs: [
        {
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
  },
  created() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    this.date = year + "-" + month + "-" + day;
    this.getData();
    this.decode();
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const title = this.event.title;
        const category = this.event.category;
        let calendarApi = this.newEventData.view.calendar;

        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          start: this.newEventData.date,
          end: this.newEventData.date,
          classNames: [category],
        });
        this.successmsg();
        this.showModal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.getData();
      this.selectedDate();
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.editcategory = this.edit.classNames[0];
      this.eventModal = true;
    },

    closeModal() {
      this.eventModal = false;
    },

    selectedDate() {
      this.getData();
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },

    getData: function() {
      this.overlayFlag = true;
      this.isLoading = true;
      useNetw
        .get("api/service", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranch,
            svDate: this.date,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.to = response.data.to;
          this.rows.from = response.data.from;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    decode() {
      // var string = "Hello World!";
      // Encode the String
      // var encodedString = btoa(string);
      // Decode the String
      // var decodedString = atob(encodedString);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <span class="m-3"
        ><code>* </code>หมายเหตุ: <code>กรอบสีแดง BP ยังไม่ได้รับ,</code>
        <code class="font-green">กรอบสีเขียว BP รับเรื่องแล้ว ,</code>
        <code>พื้นหลังสีแดง มีบาดแผล ,</code>
        <code class="font-green">พื้นหลังสีเขียว ไม่มีบาดแผล </code></span
      >
    </div>
    <div class="row">
      <div class="col-12 col-sm-5 col-md-5">
        <!-- <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar> -->
        <div class="mb-4">
          <DatePicker
            v-model="date"
            color="blue"
            :attributes="attrs"
            :model-config="modelConfig"
            @dayclick="selectedDate"
            title-position="right"
            is-expanded
          />
        </div>
      </div>
      <div class="col-12 col-sm-7 col-md-7">
        <div class="card">
          <div class="card-body">
            <span class="font-size-16">รายการใบประเมิน</span>
            <span class="font-size-14 float-end"
              >จำนวน : {{ this.rows.total }} รายการ</span
            >
            <hr />
            <div class="text-end">
              <b-button
                v-b-modal.modalService
                class="btn m-1"
                variant="primary"
              >
                <i class="uil-plus"></i>
                เปิด Service
              </b-button>
              <b-button
                v-b-modal.modalServiceFast
                class="btn m-1"
                variant="primary"
              >
                <i class="uil-plus"></i>
                เปิด Service แบบด่วน
              </b-button>
              <b-button
                :to="{
                  name: 'servicelist',
                }"
                class="btn m-1"
                variant="primary"
              >
                <i class="uil-table"></i>
              </b-button>
            </div>
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <div class="row mt-4">
                <span
                  v-if="this.rows.total === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div
                  class="col-6 col-sm-6 col-md-4"
                  v-for="(data, index) in rows"
                  :key="index"
                >
                  <div class="product-box">
                    <div class="product-img pt-1 px-1">
                      <div class="product-ribbon badge badge-danger"></div>
                      <img
                        :src="data.path"
                        alt
                        height="600"
                        class="img-fluid mx-auto d-block"
                        style="height: 10rem"
                      />
                      <div class="row mt-4">
                        <div class="col-12 text-center">
                          <div>{{ data.licensePlate }}</div>
                          <div>{{ data.cusName }}</div>
                          <div>{{ data.vehicleModelEn }}</div>
                          <div>{{ data.distanceNameTh }}</div>
                        </div>
                      </div>
                      <div class="mt-4 mb-4 col-12 text-center">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 btn btn-primary btn-sm text-light"
                            v-b-tooltip.hover
                            :to="{
                              name: 'servicedetail',
                              params: {
                                id: data.svIdBase64,
                                url: 'servicegallery',
                              },
                            }"
                            title="Edit"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 btn btn-warning btn-sm text-light"
                            v-b-tooltip.hover
                            :to="{
                              name: 'editCheckMycar',
                              params: { svId: data.svIdBase64 },
                            }"
                            title="View"
                          >
                            <i class="uil uil-eye font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 btn btn-danger btn-sm text-light"
                            v-b-tooltip.hover
                            title="Delete"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col" v-if="this.rows.total > 0">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                </div>
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
    <modal-service-component></modal-service-component>
  </Layout>
</template>

<style>
/* .contents {
  position: relative;
}
@media (max-width: 972px){
  .contents .col-md-4 {
      width: 100%;
  }
  .contents .col-md-8 {
      width: 100%;
  }
} */

.font-green {
  color: green;
}

.img-fluid-estimation {
  max-width: 100%;
  height: 10rem;
}
</style>
